.home-nav{
    color: black;
}

.card{
    border-radius:30px !important;
}

.card-header, .card-body, .card-footer{
    border-top-right-radius:30px !important;
    border-top-left-radius:30px !important;
}

.home-nav{
    z-index:20;
}

.body-container{
    padding-top: 10px;
}